<template>
  <v-main>
    <v-container fluid>
      <v-flex class="wrap-page ma-auto">
        <v-card class="mx-auto px-2 py-2 pr" color="grey darken-3">
          <v-card-title class="justify-center">
            <ProfileImage/>
          </v-card-title>
          <v-card-text class="mt-4">
            <div v-if="forumApps.length > 0" class="mb-6">
              <p :class="['title', 'mb-1', $vuetify.breakpoint.smAndUp ? 'text-left ml-1' : 'text-center']">
                Communities & Schools
              </p>
              <v-row no-gutters>
                <v-col v-for="(client, k) in forumApps" :key="k" cols="12" xl="3" lg="3" md="4" sm="6">
                  <div class="ma-1">
                    <v-btn
                      block
                      x-large
                      :color="client.button && client.button.color ? client.button.color : 'appsBut2'"
                      dark
                      :class="['mb-2', 'text-none', $vuetify.breakpoint.smAndUp ? 'custom-btn-type-1' : '']"
                      @click="goToClientApp(client)"
                    >
                      <v-img
                        v-if="client.button && client.button.icon"
                        :src="client.button.icon.url"
                        :max-height="client.button.icon['max-height'] || 24"
                        :max-width="client.button.icon['max-width'] || 24"
                        :class="$vuetify.breakpoint.smAndUp ? 'mb-2' : 'mr-1'"
                        contain
                      ></v-img>
                      <span>{{ client.name }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="commonApps.length > 0 || accountAdministrationButton">
              <p :class="['title', 'mb-1', $vuetify.breakpoint.smAndUp ? 'text-left ml-1' : 'text-center']">Apps</p>
              <v-row v-if="commonApps.length > 0" no-gutters>
                <v-col v-for="(client, k) in commonApps" :key="k" cols="12" xl="3" lg="3" md="4" sm="6">
                  <div class="ma-1">
                    <v-btn
                      block
                      x-large
                      color="appsBut"
                      dark
                      :class="['mb-2', 'text-none', $vuetify.breakpoint.smAndUp ? 'custom-btn-type-1' : '']"
                      @click="goToClientApp(client)"
                    >
                      <v-img
                        v-if="client.button && client.button.icon"
                        :src="client.button.icon.url"
                        :max-height="client.button.icon['max-height'] || 24"
                        :max-width="client.button.icon['max-width'] || 24"
                        :class="$vuetify.breakpoint.smAndUp ? 'mb-2' : 'mr-1'"
                        contain
                      ></v-img>
                      <span>{{ client.name }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';
import ProfileImage from '@/components/ProfileImage.vue';

export default {
  name: 'Profile',
  mixins: [CommonMixin],
  components: { ProfileImage},
  computed: {
    ...mapGetters({
      user: 'getUser',
      customer: 'getCustomer'
    }),
    forumApps() {
      const forumApps = this.user.LuceoApps.filter(app => app.group === 'community');
      const memberProfileDomainCustomer = this.user.Customers.find(c => !!c.MemberProfileDomain);
      if (!memberProfileDomainCustomer) return forumApps;
      const coachProfileApp = {
        name: 'Public Coaching Profile',
        to: { name: 'EditProfileExternal' },
        button: {
          icon: {
            url: '/img/icons/profile-app-icon.png'
          }
        }
      };
      return [coachProfileApp, ...forumApps];
    },
    commonApps() {
      return this.user.LuceoApps.filter(app => app.group !== 'community');
    }
  },
  methods: {
    goToClientApp(client) {
      if (client.to) return this.$router.push(client.to);
      if (client.auth_url) return (location.href = client.auth_url);
      if (client.base_url) return (location.href = client.base_url);
    }
  }
};
</script>

<style>
.custom-btn-type-1 {
  height: 65px !important;
}
.custom-btn-type-1 .v-btn__content {
  flex-direction: column;
}
</style>
