<template>
  <div>
    <p v-if="field.title" class="text-capitalize subtitle-2 mb-0">{{ field.title }}:</p>
    <v-row dense>
      <v-col v-for="child in field.fields" :key="child.name" cols="12" :sm="child.size || 12">
        <div :class="$vuetify.breakpoint.smAndUp ? child.cssClass : 'ma-0'">
          <component
            :is="child.type"
            :field="child"
            :value="prepareChildValue(child)"
            :entry="value"
            @changed="childFieldValueChanged(child, $event)"
          ></component>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CustomField',
  props: ['value', 'field'],
  methods: {
    prepareChildValue(child) {
      if (child.type.name === 'ToggleBtnCustomField') return child.value(this.value);
      if (this.value && this.value[child.name]) return this.value[child.name];
      return child.type.name === 'CustomField' ? {} : null;
    },
    childFieldValueChanged({ name, type }, event) {
      const key = type.name === 'ToggleBtnCustomField' ? event.field.name : name;
      const value = type.name === 'ToggleBtnCustomField' ? event.value : event;
      const newValue = this.field.preProcessChange ? this.field.preProcessChange(this.value, key, value) : this.value;
      this.$emit('changed', { ...newValue, [key]: value });
      this.$emit('input', { ...newValue, [key]: value });
    }
  }
};
</script>

<style scoped></style>
