<template>
  <v-combobox
    label="Switch to user"
    :search-input.sync="search"
    :items="items"
    :loading="loading"
    hint="type at least 3 characters"
    placeholder="Start typing to Search"
    no-data-text="No data found matching your query"
    hide-selected
    class="mb-1"
    @input="onInput"
  ></v-combobox>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'OtherUserSearch',
  props: [],
  data: () => ({
    search: null,
    loading: false,
    items: []
  }),
  computed: {
    ...mapGetters({
      config: 'getConfig'
    })
  },
  watch: {
    async search(value) {
      if (!value || value.length < 3) return;
      this.loading = true;
      setTimeout(async () => {
        if (value === this.search) {
          let res = await fetch(`${this.config.baseUrl}/api/users/external-profile-options?q=${value}`);
          if (res.ok) {
            this.items = await res.json();
          }
        }
        this.loading = false;
      }, 500);
    }
  },
  methods: {
    ...mapMutations(['setOtherUser']),
    async onInput(value) {
      if (typeof value.value === 'undefined') return;
      let res = await fetch(`${this.config.baseUrl}/api/users/${value.value}`);
      if (res.ok) {
        this.setOtherUser(await res.json());
      }
    }
  }
};
</script>

<style>
.v-messages.theme--light {
  color: hsla(0, 0%, 100%, 0.7);
}
</style>
