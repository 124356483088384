<template>
  <div>
    <p v-if="field.title">{{ field.title }}</p>
    <v-textarea
      :value="value"
      :rules="field.rules"
      :placeholder="field.props.placeholder"
      :readonly="field.props.readonly"
      :disabled="field.props.disabled"
      :counter="field.props.counter"
      solo
      light
      @input="onInput"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'TextAreaField',
  props: ['value', 'field'],
  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.$emit('changed', value);
    }
  }
};
</script>

<style scoped></style>
