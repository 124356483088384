import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/pages/Login';
import Logout from '@/pages/Logout';
import ForgotPassword from '@/pages/ForgotPassword';
import SetPassword from '@/pages/SetPassword';
import Profile from '@/pages/Profile';
import EditProfile from '@/pages/EditProfile';
import SignUp from '@/pages/SignUp';
import OAuthAuthorize from '@/pages/OAuthAuthorize';
import EditProfileGeneral from '@/pages/EditProfile/EditProfileGeneral';
import EditProfileExternal from '@/pages/EditProfile/EditProfileExternal';

Vue.use(Router);

export default new Router({
  hashbang: false,
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/:customerSlug/login/',
      name: 'Login',
      component: Login
    },
    {
      path: '/:customerSlug/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/:customerSlug/oauth/authorize',
      name: 'OAuthAuthorize',
      component: OAuthAuthorize
    },
    {
      path: '/:customerSlug/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/:customerSlug/set-password/:token',
      name: 'SetPassword',
      component: SetPassword
    },
    {
      path: '/:customerSlug/profile/edit',
      name: 'EditProfile',
      component: EditProfile,
      redirect: { name: 'EditProfileGeneral' },
      children: [
        {
          path: 'general',
          name: 'EditProfileGeneral',
          component: EditProfileGeneral
        },
        {
          path: 'external/:otherUserId?',
          name: 'EditProfileExternal',
          component: EditProfileExternal
        }
      ]
    },
    {
      path: '/:customerSlug/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/:customerSlug/sign-up',
      name: 'SignUp',
      component: SignUp
    }
  ]
});
