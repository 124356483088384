import Vue from 'vue';
import _ from 'lodash';
import store from './store';

Vue.filter('customerBasedUrl', function(path, defaultUrl) {
  if (!path && defaultUrl) {
    return defaultUrl;
  }
  const config = store.getters.getConfig;
  const customer = store.getters.getCustomer;
  return config.baseUrl + '/' + customer.Slug + '/' + _.trimStart(path, '/');
});

Vue.filter('storageUrl', function(filePath, defaultUrl = '', container = 'public') {
  if (!filePath && defaultUrl) {
    return defaultUrl;
  }
  const config = store.getters.getConfig;
  return `${config.storageUrl}/${container}/${_.trimStart(filePath, '/')}`;
});
