<template>
  <div>
    <p v-if="field.title" class="text-capitalize subtitle-2 mb-0">{{ field.title }}:</p>

    <v-row dense>
      <v-col cols="12" :sm="field.size ? field.size.sm || field.size : 12">
        <div :class="$vuetify.breakpoint.smAndUp ? field.cssClass : 'ma-0'">
          <v-btn-toggle :value="selectedItemIndex" tile color="#ef6c00" group @change="changeToggle($event)">
            <v-btn v-for="item in field.items" :key="item.name">
              {{ item.label || item.name }}
            </v-btn>
          </v-btn-toggle>
          <p v-if="selectedItem.description" class="description-toggle-field">{{ selectedItem.description }}</p>
          <div v-for="item in field.items" v-show="selectedItem && selectedItem.name === item.name" :key="item.name">
            <v-row dense>
              <v-col
                v-for="child in item.fields"
                :key="child.name"
                cols="12"
                :sm="child.size ? child.size.sm || child.size : 12"
              >
                <div :class="$vuetify.breakpoint.smAndUp ? child.cssClass : 'ma-0'">
                  <component
                    :is="child.type"
                    :field="child"
                    :value="prepareChildValue(child)"
                    :entry="entry"
                    @changed="childFieldValueChanged(child, $event)"
                  ></component>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ToggleBtnCustomField',
  props: ['value', 'entry', 'field'],
  data: () => {
    return {
      localValue: null
    };
  },
  computed: {
    selectedItem() {
      return this.field.items.find(item => item.name === this.localValue);
    },
    selectedItemIndex() {
      return this.localValue ? this.field.items.findIndex(item => item.name === this.localValue) : 0;
    }
  },
  created() {
    this.localValue = this.value;
  },
  methods: {
    prepareChildValue(child) {
      if (this.entry[child.name]) return this.entry[child.name];
      return child.type === 'CustomField' ? {} : null;
    },
    childFieldValueChanged(field, value) {
      this.$emit('changed', { field, value });
      this.$emit('input', { field, value });
    },
    changeToggle(itemIndex) {
      const item = this.field.items[itemIndex];
      this.localValue = item.name;
    }
  }
};
</script>

<style scoped>
.description-toggle-field {
  margin: 10px 0;
}
</style>
