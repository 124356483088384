export default {
  methods: {
    emitEventInfoToParent(event) {
      clearTimeout(window.emitEventInfoToParent);
      window.emitEventInfoToParent = setTimeout(() => {
        this.postMessageToParentFrame({
          type: 'domEventFired',
          payload: { event: event.type, route: this.$route.name, element: event.target.dataset.name }
        });
      }, 100);
    },
    postMessageToParentFrame(payload) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(payload));
      } else {
        window.parent.postMessage({ source: '@luceosports/authentication-service-frontend', ...payload }, '*');
      }
    }
  }
};
