<template>
  <a target="_blank" class="external-link" :href="href">
    <v-icon :color="color">open_in_new</v-icon>
  </a>
</template>

<script>
export default {
  name: 'ExternalProfileLink',
  props: {
    color: {
      type: String,
      required: true,
      default: '#2196f3'
    },
    href: {
      type: String,
      required: true,
      default: '/'
    }
  }
};
</script>

<style scoped>
.external-link {
  text-decoration: none;
}
</style>
