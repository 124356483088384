<template>
  <v-row>
    <v-col class="d-flex align-center">
      <router-link :to="{ name: 'Profile', params: { customerSlug: customer.Slug } }">
        <img class="logo-small" :src="customer.Logo" :alt="customer.Name" />
      </router-link>
      <img v-show="showOnStaging" class="dev-ribbon" src="../assets/dev_ribbon.png" />
    </v-col>
    <v-col class="text-right">
      <v-avatar color="grey darken-2" class="mr-2" size="40">
        <v-img v-if="user.headshot_image_path" class="logo-small" :src="user.headshot_image_path" />
        <v-icon v-else>mdi-account-circle</v-icon>
      </v-avatar>
      <span class="subtitle-2 mr-0" style="word-break: break-word">{{ user.User_Name }}</span>
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item :to="{ name: 'EditProfile', params: { customerSlug: customer.Slug } }">
            <v-list-item-title>Edit profile</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Logout', params: { customerSlug: customer.Slug } }">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="12" class="py-0">
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from '@/mixins/CommonMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileImage',
  mixins: [CommonMixin],
  computed: {
    ...mapGetters({
      user: 'getUser',
      customer: 'getCustomer',
    }),
  },
};
</script>

<style scoped></style>
