<template>
  <v-main>
    <v-container fluid>
      <v-flex class="wrap-page ma-auto">
        <v-card class="mx-auto px-2 py-2 pr" color="grey darken-3">
          <v-card-title class="justify-center">
            <ProfileImage/>
          </v-card-title>
          <v-card-text class="mt-0">
            <v-tabs v-show="externalProfileTabVisible" centered background-color="grey darken-3" color="appsBut2">
              <v-tab :to="{ name: 'EditProfileGeneral' }"
                ><v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mr-1">mdi-settings</v-icon>General</v-tab
              >
              <v-tab :to="{ name: 'EditProfileExternal' }"
                ><v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mr-1">mdi-account</v-icon>External Profile</v-tab
              >
            </v-tabs>
            <div class="pt-6">
              <router-view></router-view>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';
import ProfileImage from '@/components/ProfileImage.vue';

export default {
  name: 'EditProfile',
  components: { ProfileImage },
  mixins: [CommonMixin],
  computed: {
    ...mapGetters({
      customer: 'getCustomer',
      user: 'getUser'
    }),
    externalProfileTabVisible() {
      return this.user.IsGlobalAdmin || this.user.Customers.some(c => !!c.MemberProfileDomain);
    }
  }
};
</script>

<style scoped></style>
