<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';
export default {
  name: 'Logout',
  mixins: [CommonMixin],
  computed: {
    ...mapGetters({
      config: 'getConfig',
    }),
  },
  created: function () {
    let logoutUrl = this.$options.filters.customerBasedUrl('logout');
    window.location.href = this.buildUrlWithParams(logoutUrl, this.oauthQueryParams);
  },
};
</script>

<style scoped></style>
