<template>
  <v-app>
    <notifications group="main" position="top center" />
    <Preloader></Preloader>
    <router-view></router-view>
  </v-app>
</template>

<script>
import Preloader from '@/components/Preloader';
import NotificationMixin from '@/mixins/NotificationMixin';
export default {
  name: 'App',
  components: { Preloader },
  mixins: [NotificationMixin],
  created: function() {
    this.$vuetify.theme.dark = true;
  },
  mounted() {
    let extraData = this.$store.getters.getExtraData;
    if (extraData && extraData.flash) {
      for (let type in extraData.flash) {
        let typeMessages = extraData.flash[type];
        typeMessages.forEach(function(message) {
          this.showMessage({ type: type, title: 'Notice', text: message });
        }, this);
      }
    }
  }
};
</script>

<style>
.theme--dark.v-application {
  background: #303030 !important;
}

.form-type-1 {
  max-width: 440px !important;
  margin: 0px auto;
}

.form-type-2 {
  max-width: 550px !important;
  margin: 0px auto;
}

.wrap-page {
  max-width: 1280px !important;
  width: 100%;
}

.v-application .error--text.v-input.v-text-field .v-input__slot {
  border: 1px solid #ff5252;
}
.form-page .v-text-field .v-input__slot {
  padding: 0px 1px !important;
  border: 1px solid #fff;
  margin-bottom: 4px;
}

.form-page .v-text-field input {
  text-indent: 10px;
}

.form-page .v-input input {
  min-height: 45px;
  border-radius: 3px;
}

.logo-small {
  max-height: 58px;
  position: relative;
  z-index: 2;
}

.pr {
  position: relative;
}

.dev-ribbon {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
</style>
