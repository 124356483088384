<template>
  <v-text-field
    :value="value"
    :rules="field.rules"
    :placeholder="field.props.placeholder"
    :readonly="field.props.readonly"
    :disabled="field.props.disabled"
    :hint="field.props.hint"
    :persistent-hint="field.props['persistent-hint']"
    solo
    light
    class="mb-1"
    dense
    @input="onInput"
    ><v-icon v-if="field.prependIcon" slot="prepend" color="appsBut2">{{ field.prependIcon }}</v-icon></v-text-field
  >
</template>

<script>
export default {
  name: 'TextField',
  props: ['value', 'field'],
  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.$emit('changed', value);
    }
  }
};
</script>

<style>
.v-messages.theme--light {
  color: hsla(0, 0%, 100%, 0.7);
}
</style>
