<template>
  <v-main>
    <v-container fluid class="fill-height">
      <v-flex class="form-type-1">
        <v-card class="mx-auto px-2 py-5 form-page pr" color="grey darken-3">
          <div class="d-flex justify-center mb-3 pt-6">
            <img class="logo-small" :src="customer.Logo" :alt="customer.name" />
            <img v-show="showOnStaging" class="dev-ribbon" src="../assets/dev_ribbon.png" />
          </div>
          <v-card-title class="justify-center">
            Authorize
          </v-card-title>
          <v-card-text class="mt-4">
            <p>You are logged in as user:<br />{{ user.User_Name }} - {{ user.Email }}</p>
            <p>Client {{ client.name }} will receive following permissions: {{ $route.query.scope }}</p>

            <div class="d-block">
              <form ref="authorizeForm" method="post" :action="config.baseUrl + '/oauth/authorize'">
                <input type="hidden" name="_csrf" :value="csrfToken" />
                <input v-for="(v, k) in oauthQueryParams" :key="k" type="hidden" :name="k" :value="v" />
                <input v-if="rememberAuth" type="hidden" name="remember" value="1" />
                <v-checkbox
                  v-model="rememberAuth"
                  :color="customer.PrimaryColor"
                  label="Remember my decision"
                ></v-checkbox>
                <v-btn :color="customer.PrimaryColor" class="px-5 text-capitalize" @click="authorize">
                  Authorize
                </v-btn>
                <router-link
                  :style="linkStyles"
                  :to="{ name: 'Logout', params: { customerSlug: customer.slug }, query: oauthQueryParams }"
                  >Logout
                </router-link>
              </form>
              <!--<v-btn-->
              <!--:color="customer.primaryColor"-->
              <!--:to="{ name: 'Logout', query: this.oauthQueryParams }"-->
              <!--class="px-5 text-capitalize"-->
              <!--&gt;-->
              <!--Logout-->
              <!--</v-btn>-->
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  name: 'OAuthAuthorize',
  mixins: [CommonMixin],
  data() {
    return {
      rememberAuth: true,
      customer: {}
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      client: 'getClient',
      config: 'getConfig',
      extraData: 'getExtraData',
      csrfToken: 'getCsrfToken'
    }),
    linkStyles() {
      return 'color: ' + this.customer.SecondaryColor;
    }
  },
  created: function() {
    this.customer = this.$store.getters.getCustomer;
  },
  mounted: function() {
    if (this.extraData.rememberAuthorize) {
      this.authorize();
    }
  },
  methods: {
    authorize() {
      this.$eventHub.$emit('before-request');
      this.$refs.authorizeForm.submit();
    }
  }
};
</script>

<style></style>
