<template>
  <v-main>
    <v-container fluid class="fill-height">
      <v-flex class="form-type-1">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
          <v-card class="mx-auto px-2 py-5 form-page pr" color="grey darken-3">
            <div class="d-flex justify-center mb-3 pt-6">
              <img class="logo-small" :src="customer.Logo" :alt="customer.Name" />
              <img v-show="showOnStaging" class="dev-ribbon" src="../assets/dev_ribbon.png" />
            </div>
            <v-card-title class="justify-center flex-column">
              <span>Set Password</span>
              <p
                class="justify-center text-center subtitle-2 grey--text text--lighten-2 mt-4"
                style="word-break: break-word"
              >
                Password change for account: {{ extraData.passwordReset.Email }}
              </p>
            </v-card-title>
            <v-card-text class="mt-4">
              <v-text-field
                v-model="pwd"
                :rules="pwdRules"
                placeholder="New Password"
                type="password"
                required
                solo
                light
                class="mb-1"
              ></v-text-field>

              <v-text-field
                v-model="pwdConfirmation"
                :rules="pwdConfirmationRules"
                placeholder="New Password Again"
                type="password"
                required
                solo
                light
                class="mb-1"
              ></v-text-field>

              <div class="d-block">
                <v-btn type="submit" :color="customer.PrimaryColor" class="px-5 text-capitalize">
                  Save
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import NotificationMixin from '../mixins/NotificationMixin';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  name: 'SetPassword',
  mixins: [NotificationMixin, CommonMixin],
  data: () => ({
    customer: {},
    valid: true,
    pwd: '',
    pwdConfirmation: ''
  }),

  computed: {
    ...mapGetters({
      config: 'getConfig',
      extraData: 'getExtraData'
    }),
    pwdConfirmationRules() {
      return [
        () => this.pwd === this.pwdConfirmation || 'Passwords must match',
        v => !!v || 'New Password Confirmation is required'
      ];
    },
    pwdRules() {
      return [
        v => !!v || 'New Password is required',
        v =>
          new RegExp(this.config.constants.AZURE_PWD_REGEXP).test(v) ||
          'Passwords must be at least 8 characters and contain at least (1) capital letter, number, and symbol'
      ];
    }
  },

  created: function() {
    this.customer = this.$store.getters.getCustomer;
  },

  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        try {
          let res = await axios.post(
            this.$options.filters.customerBasedUrl(`set-password/${this.extraData.passwordReset.Token}`),
            {
              password: this.pwd
            }
          );
          if (res.data.error) throw new Error(res.data.error);

          let extraData = this.$store.getters.getExtraData;
          if (extraData.nativeAppRedirectUri) {
            this.showMessage(res.data.message);
            return (window.location.href = extraData.nativeAppRedirectUri);
          }
          this.$router.push({ name: 'Login' });
          this.showMessage(res.data.message);
        } catch (e) {
          console.error(e);
          this.showValidationError(this.buildValidationErrorDataFromException(e));
        }
      }
    }
  }
};
</script>

<style scoped></style>
