<template>
  <div>
    <FilePond
      ref="filePond"
      credits="false"
      :allow-multiple="false"
      :files="files"
      :image-editor="myEditor"
      :accepted-file-types="acceptedFileTypes"
      :image-editor-instant-edit="true"
      :allowFileSizeValidation="true"
      :allowImageResize="true"
      :imageResizeTargetWidth="300"
      :imageResizeTargetHeight="300"
      :imageValidateSizeMinWidth="300"
      :imageValidateSizeMinHeight="300"
      :server="server()"
      @init="handleFilePondInit"
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import { mapGetters } from 'vuex';

import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';

import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,

  // filepond
  openEditor,
  processImage,
  createDefaultImageOrienter,
  legacyDataToImageState
} from '@pqina/pintura';


setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImageEditor, FilePondPluginFilePoster, FilePondPluginImageValidateSize, FilePondPluginImageResize);
export default {
  name: 'FilePondUploadGeneral',
  components: { FilePond },
  props: ['value', 'field'],
  data: () => ({
    files: [],
    acceptedFileTypes: ['image/*'],
    errorMessage: '',
    myEditor: {
      // map legacy data objects to new imageState objects
      legacyDataToImageState: legacyDataToImageState,

      // used to create the editor, receives editor configuration, should return an editor instance
      createEditor: openEditor,

      // Required, used for reading the image data
      imageReader: [createDefaultImageReader],

      // optionally. can leave out when not generating a preview thumbnail and/or output image
      imageWriter: [
        createDefaultImageWriter,
        {
          targetSize: {
            width: 300,
            height: 300,
          },
        },
      ],

      // used to generate poster images, runs an editor in the background
      imageProcessor: processImage,

      // editor options
      editorOptions: {
        imageCropAspectRatio: 1,
        imageCropMinSize: { width: 300, height: 300 },
        imageOrienter: createDefaultImageOrienter(),
        ...plugin_finetune_defaults,
        ...plugin_filter_defaults,
        ...markup_editor_defaults,
        locale: {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
          ...plugin_filter_locale_en_gb,
          ...plugin_annotate_locale_en_gb,
          ...markup_editor_locale_en_gb
        }
      }
    }
  }),
  computed: {
    ...mapGetters({
      baseUser: 'getUser',
      otherUser: 'getOtherUser',
    }),
    user() {
      return this.otherUserSet ? this.otherUser : this.baseUser;
    },
  },
  methods: {
    changeImageValue(value) {
      this.$emit('imageChanged', value);
    },
    removeImage() {
      this.changeImageValue(null);
    },
    async handleFilePondInit() {
      try {
        if (this.baseUser.headshot_image_path) {
          const azureFileUrl = this.baseUser.headshot_image_path;
          // Fetch the file data
          const response = await fetch(azureFileUrl);
          const fileBlob = await response.blob();
          const file = new File([fileBlob], 'filename', { type: fileBlob.type });

          // Add the file to FilePond
          this.files.push({
            source: file,
            options: {
              type: 'local',
              metadata: {
                filename: 'filename',
                fileType: file.type
              }
            }
          });
        }
      } catch (error) {
        console.error('Error fetching the file:', error);
      }
    },
    server() {
      return {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const source = this.axios.CancelToken.source();

          const formData = new FormData();
          formData.append('file', file, file.name);

          const maxFileSizeMB = 2000;
          const fileSizeMB = file.size / 1000 / 1000;
          if (fileSizeMB > maxFileSizeMB) {
            console.log('Request canceled - file too large', file);
            abort();
            return this.showValidationError(
              `File upload cancelled - file too large. File size should be less than ${maxFileSizeMB} MB`
            );
          }

          this.axios
            .post(this.$options.filters.customerBasedUrl(`/profile/edit-general/file-upload/${this.user.id}`),
              formData, {
                onUploadProgress: function (e) {
                  progress(e.lengthComputable, e.loaded, e.total);
                },
              })
            .then((res) => {
              const { imagePath } = res.data;

              load(imagePath);
              this.changeImageValue(imagePath);
            })
            .catch((e) => {
              if (this.axios.isCancel(e)) {
                return console.log('Request canceled', e.message);
              }
              console.error(e);
              this.showValidationError(this.buildValidationErrorDataFromException(e));
            });

          return {
            abort: () => {
              source.cancel('Operation canceled by the user.');
              abort();
            },
          };
        },
        remove: (source, load) => {
          this.removeImage(null);
          load();
        }
      };
    },
  }
};
</script>

<style scoped>
@import '../../../node_modules/@pqina/pintura/pintura.css';

html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1 {
  margin-top: 0;
}

body {
  padding: 2em;
}

img {
  max-width: 100%;
}
.pintura-editor {
  box-shadow: 0 0 0 1px #eee;
}
</style>
