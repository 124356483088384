<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    color="white"
    hide-no-data
    hide-selected
    item-text="name"
    item-value="id"
    :placeholder="field.props.placeholder"
    :clearable="field.props.clearable || false"
    :rules="field.rules"
    return-object
    @input="onInput"
  ></v-autocomplete>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
export default {
  name: 'AutocompleteField',
  mixins: [NotificationMixin],
  props: ['value', 'field', 'entry'],
  data: () => ({
    search: null,
    entries: []
  }),
  computed: {
    items() {
      if (this.field.optionsFilter) {
        return this.$store.getters[this.field.optionsGetterName].filter(this.field.optionsFilter(this.entry));
      }
      return this.$store.getters[this.field.optionsGetterName];
    },
    isLoading() {
      return !this.items.length;
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.$emit('changed', value);
    }
  }
};
</script>

<style scoped></style>
