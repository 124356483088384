import _ from 'lodash';
import querystring from 'querystring';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getConfig']),
    oauthQueryParams() {
      let params = {};
      ['client_id', 'redirect_uri', 'response_type', 'scope', 'state'].forEach(paramName => {
        if (this.$route.query[paramName]) {
          params[paramName] = this.$route.query[paramName];
        }
      }, this);
      return params;
    },
    showOnStaging() {
      return this.getConfig && this.getConfig.env === 'staging';
    }
  },
  methods: {
    buildUrlWithParams(path, params) {
      if (_.isEmpty(params)) return path;
      return path + '?' + querystring.stringify(params);
    }
  }
};
