<template>
  <v-main>
    <v-container fluid class="fill-height">
      <v-flex class="form-type-1">
        <v-form ref="form" v-model="valid" class="pr" lazy-validation @submit.prevent="validate">
          <v-card class="mx-auto px-2 py-5 form-page pr" color="grey darken-3">
            <div class="d-flex justify-center mb-3 pt-6">
              <img class="logo-small" :src="customer.Logo" :alt="customer.Name" />
              <img v-show="showOnStaging" class="dev-ribbon" src="../assets/dev_ribbon.png" />
            </div>
            <v-card-title class="justify-center">Login</v-card-title>
            <v-card-text class="mt-4">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                placeholder="Email"
                required
                solo
                light
                class="mb-1"
                data-name="email-input"
                @focus="emitEventInfoToParent"
              ></v-text-field>

              <v-text-field
                v-model="pwd"
                :rules="pwdRules"
                placeholder="Password"
                type="password"
                solo
                light
                required
                class="mb-1"
                data-name="password-input"
                @focus="emitEventInfoToParent"
              ></v-text-field>

              <!--<v-checkbox-->
              <!--v-model="checkbox"-->
              <!--label="Remember me"-->
              <!--color="selectedColors"-->
              <!--class="mt-0"-->
              <!--&gt;</v-checkbox>-->

              <div class="d-block">
                <v-btn
                  type="submit"
                  :color="customer.PrimaryColor"
                  class="px-5 text-capitalize"
                  data-name="login-button"
                  @click.native="emitEventInfoToParent"
                >
                  Login
                </v-btn>
                <router-link
                  data-name="forgot-button"
                  :style="linkStyles"
                  :to="{ name: 'ForgotPassword', params: { customerSlug: customer.Slug }, query: oauthQueryParams }"
                  @click.native="emitEventInfoToParent"
                  >Forgot password?</router-link
                >
              </div>
            </v-card-text>
          </v-card>
        </v-form>

        <p style="color: #ccc; font-size: 14px; text-align: center; margin: 10px 0;}">
          Support: <a :style="linkStyles" :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
        </p>
        <p v-show="showPatent" style="color: #ccc; font-size: 12px; text-align: center; margin: 0; opacity: 0.5;">
          pat. - patents.luceosports.com
        </p>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import CommonMixin from '@/mixins/CommonMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import DomEventsEmitterMixin from '@/mixins/DomEventsEmitterMixin';

export default {
  name: 'Login',
  mixins: [CommonMixin, NotificationMixin, DomEventsEmitterMixin],
  data: () => ({
    customer: {},
    logo: '',
    valid: true,
    pwd: '',
    pwdRules: [v => !!v || 'Password is required'],
    email: '',
    emailRules: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid'],
    checkbox: false
  }),

  computed: {
    ...mapGetters(['getUser', 'getConfig', 'getExtraData', 'getClient']),
    linkStyles() {
      return 'color: ' + this.customer.SecondaryColor;
    },
    supportEmail() {
      return this.customer.SupportEmail || this.getExtraData.defaults.SupportEmail;
    },
    showPatent() {
      return (this.getClient || {}).name !== 'Pure Sweat App';
    }
  },

  created: function() {
    this.customer = this.$store.getters.getCustomer;
    this.registerParentFrameEventListeners();
  },

  methods: {
    ...mapMutations(['setUser']),
    registerParentFrameEventListeners() {
      // Parent frame
      window.addEventListener('message', event => {
        window.dispatchEvent(new CustomEvent(event.data.name, { detail: event.data.detail }));
      });
      // WebView
      window.addEventListener('webViewLoginWithUserBiometrics', this.loginWithUserBiometrics);
    },
    async validate() {
      if (!this.$refs.form.validate()) return;
      await this.loginProcess({ email: this.email, password: this.pwd });
    },
    async loginWithUserBiometrics({ detail: { signature, deviceId, timeStamp, userId } }) {
      if (Object.entries(this.getUser).length) return;
      if (!signature || !deviceId || !timeStamp || !userId) return console.error('invalid input params');
      await this.loginProcess({ signature, deviceId, timeStamp, userId });
    },
    async loginProcess(loginPayload) {
      try {
        let res = await axios.post(this.$options.filters.customerBasedUrl('login'), loginPayload);
        if (res.data.error) throw new Error(res.data.error);

        this.setUser(res.data.user);

        if (this.$route.query.client_id) {
          return this.$router.push({ name: 'OAuthAuthorize', query: this.oauthQueryParams });
        }
        return this.$router.push({ name: 'Profile' });
      } catch (e) {
        console.error(e);
        this.showValidationError(this.buildValidationErrorDataFromException(e));
      }
    }
  }
};
</script>

<style></style>
