<template>
  <v-main>
    <v-container fluid class="fill-height">
      <v-flex class="form-type-1">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
          <v-card class="mx-auto px-2 py-5 form-page pr" color="grey darken-3">
            <div class="d-flex justify-center mb-3 pt-6">
              <img class="logo-small" :src="customer.Logo" :alt="customer.Name" />
              <img v-show="showOnStaging" class="dev-ribbon" src="../assets/dev_ribbon.png" />
            </div>
            <v-card-title class="justify-center">
              Password Recovery
            </v-card-title>
            <v-card-text class="mt-4">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                placeholder="Email"
                required
                solo
                light
                class="mb-1"
                data-name="email-input"
                @focus="emitEventInfoToParent"
              ></v-text-field>

              <div class="d-block">
                <v-btn
                  type="submit"
                  :color="customer.PrimaryColor"
                  class="px-5 text-capitalize"
                  data-name="submit-button"
                  @click="emitEventInfoToParent"
                >
                  Next
                </v-btn>
                &nbsp;
                <router-link
                  :style="linkStyles"
                  :to="{ name: 'Login', params: { customerSlug: customer.Slug }, query: oauthQueryParams }"
                  data-name="login-button"
                  @click.native="emitEventInfoToParent"
                  >Back to Login</router-link
                >
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import CommonMixin from '@/mixins/CommonMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import DomEventsEmitterMixin from '@/mixins/DomEventsEmitterMixin';

export default {
  name: 'ForgotPassword',
  mixins: [CommonMixin, NotificationMixin, DomEventsEmitterMixin],
  data: () => ({
    customer: {},
    valid: true,
    email: '',
    emailRules: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']
  }),

  computed: {
    ...mapGetters(['getConfig', 'getUser']),
    linkStyles() {
      return 'color: ' + this.customer.SecondaryColor;
    }
  },

  created: function() {
    this.customer = this.$store.getters.getCustomer;
  },

  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        try {
          let res = await axios.post(this.$options.filters.customerBasedUrl('forgot-password'), {
            email: this.email
          });
          if (res.data.error) throw new Error(res.data.error);

          this.showMessage(res.data.message);
        } catch (e) {
          console.error(e);
          this.showValidationError(this.buildValidationErrorDataFromException(e));
        }
      }
    }
  }
};
</script>

<style></style>
