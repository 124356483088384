import Vue from 'vue';
import Vuex from 'vuex';

Vue.config.devtools = true;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: {},
    csrfToken: '',
    user: {},
    otherUser: {},
    client: {},
    extraData: {},
    customer: {},
    teams: [],
    leagues: []
  },
  mutations: {
    setConfig(state, payload) {
      state.config = payload;
    },
    setCustomer(state, payload) {
      state.customer = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setOtherUser(state, payload) {
      state.otherUser = payload;
    },
    setUserExternalProfile(state, { field, value }) {
      if (Object.entries(state.otherUser).length) {
        state.otherUser.ExternalProfile[field.name] = value;
        return;
      }
      state.user.ExternalProfile[field.name] = value;
    },
    setClient(state, payload) {
      state.client = payload;
    },
    setExtraData(state, payload) {
      state.extraData = payload;
    },
    setCsrfToken(state, payload) {
      state.csrfToken = payload;
    },
    setTeams(state, payload) {
      state.teams = payload;
    },
    setLeagues(state, payload) {
      state.leagues = payload;
    }
  },
  getters: {
    getConfig: state => {
      return state.config;
    },
    getUser: state => {
      return state.user;
    },
    getOtherUser: state => {
      return state.otherUser;
    },
    getUserExternalProfile: state => {
      return Object.entries(state.otherUser).length ? state.otherUser.ExternalProfile : state.user.ExternalProfile;
    },
    getClient: state => {
      return state.client;
    },
    getExtraData: state => {
      return state.extraData;
    },
    getCsrfToken: state => {
      return state.csrfToken;
    },
    getCustomer: state => {
      return state.customer;
    },
    getCustomerBySlug: state => slug => {
      return state.customers.find(customer => customer.Slug === slug);
    },
    getTeamSelectOptions: state => {
      return state.teams.map(item => ({ id: item.id, name: item.Team_Name, leagueId: item.Default_League_Id }));
    },
    getLeagueSelectOptions: state => {
      return state.leagues.map(item => ({ id: item.id, name: item.League_Name }));
    }
  },
  actions: {
    fetchTeams({ state, commit }) {
      if (!state.teams.length) {
        fetch(`${state.config.baseUrl}/api/teams`)
          .then(res => res.json())
          .then(res => commit('setTeams', res.data.entries));
      }
    },
    fetchLeagues({ state, commit }) {
      if (!state.leagues.length) {
        fetch(`${state.config.baseUrl}/api/leagues`)
          .then(res => res.json())
          .then(res => commit('setLeagues', res.data.entries));
      }
    }
  }
});
