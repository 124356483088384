<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <div class="mx-2">
          <v-card color="grey darken-2">
            <v-card-text>
              <p class="title text-center">Edit Profile:</p>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="user.User_Name"
                  :rules="userNameRules"
                  placeholder="User Name"
                  required
                  solo
                  light
                  class="mb-1"
                ></v-text-field>

                <v-text-field
                  v-model="user.Email"
                  :rules="emailRules"
                  placeholder="Email"
                  required
                  solo
                  light
                  class="mb-1"
                ></v-text-field>

                <p>Profile image</p>
                <FilePondUploadGeneral @imageChanged="childFieldValueChanged"></FilePondUploadGeneral>

                <div class="d-block">
                  <v-btn :color="customer.PrimaryColor" class="px-5 text-capitalize" @click="saveUser">
                    Save
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" :class="$vuetify.breakpoint.mdAndUp ? 'mt-0' : 'mt-8'">
        <div class="mx-2">
          <v-card color="grey darken-2">
            <v-card-text>
              <p class="title text-center">Change Password:</p>
              <v-form ref="form2" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="pwdOld"
                  :rules="pwdOldRules"
                  placeholder="Old Password"
                  type="password"
                  required
                  solo
                  light
                  class="mb-1"
                ></v-text-field>

                <v-text-field
                  v-model="pwd"
                  :rules="pwdRules"
                  placeholder="New Password"
                  type="password"
                  required
                  solo
                  light
                  class="mb-1"
                ></v-text-field>

                <v-text-field
                  v-model="pwdConfirmation"
                  :rules="pwdConfirmationRules"
                  placeholder="New Password Again"
                  type="password"
                  required
                  solo
                  light
                  class="mb-1"
                ></v-text-field>

                <div class="d-block">
                  <v-btn :color="customer.PrimaryColor" class="px-5 text-capitalize" @click="validatePassword">
                    Save
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import FilePondUploadGeneral from '@/components/fields/FilePondUploadGeneral.vue';

export default {
  name: 'EditProfileGeneral',
  components: { FilePondUploadGeneral },
  mixins: [NotificationMixin],
  data: () => ({
    valid: true,
    userNameRules: [v => !!v || 'User Name is required'],
    email: 'test@test.com',
    emailRules: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid'],
    pwd: '',
    pwdOld: '',
    pwdConfirmation: '',
    pwdOldRules: [v => !!v || 'Old Password is required'],
    headshotImagePathNew: '',
    headshotImagePathModified: false
  }),
  computed: {
    ...mapGetters({
      customer: 'getCustomer',
      user: 'getUser',
      config: 'getConfig'
    }),
    pwdConfirmationRules() {
      return [
        () => this.pwd === this.pwdConfirmation || 'Passwords must match',
        v => !!v || 'New Password Confirmation is required'
      ];
    },
    pwdRules() {
      return [
        v => !!v || 'New Password is required',
        v =>
          new RegExp(this.config.constants.AZURE_PWD_REGEXP).test(v) ||
          'Passwords must be at least 8 characters and contain at least (1) capital letter, number, and symbol'
      ];
    }
  },

  methods: {
    ...mapMutations(['setUser']),
    async saveUser() {
      if (this.$refs.form.validate()) {
        try {
          const data = {
            User_Name: this.user.User_Name,
            Email: this.user.Email
          };
          if (this.headshotImagePathModified) {
            data.headshot_image_path = this.headshotImagePathNew;
          }
          let res = await axios.put(this.$options.filters.customerBasedUrl(`profile/edit/${this.user.id}`), data);
          if (res.data.error) throw new Error(res.data.error);

          this.setUser(res.data.user);

          const responseMessages = Array.isArray(res.data.message) ? res.data.message : [res.data.message];
          responseMessages.forEach(msg => this.showMessage(msg));
        } catch (e) {
          console.error(e);
          this.showValidationError(this.buildValidationErrorDataFromException(e));
        }
      }
    },
    async validatePassword() {
      if (this.$refs.form2.validate()) {
        try {
          let res = await axios.put(this.$options.filters.customerBasedUrl(`profile/password/${this.user.id}`), {
            password: this.pwd,
            password_old: this.pwdOld
          });
          if (res.data.error) throw new Error(res.data.error);

          this.showMessage(res.data.message);
        } catch (e) {
          console.error(e);
          this.showValidationError(this.buildValidationErrorDataFromException(e));
        }
      }
    },
    childFieldValueChanged(value) {
      this.headshotImagePathNew = value
      this.headshotImagePathModified = true
    },
  }
};
</script>

<style scoped></style>
