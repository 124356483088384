<template>
  <div>
    <p v-if="field.label" class="subtitle-2">{{ field.label }}</p>
    <div v-if="value.length > 0">
      <ol>
        <li v-for="(item, index) in value" :key="index">
          <div style="display: flex; align-items: baseline;">
            <CustomField :value="item" :field="field" @changed="changeItem($event, index)"></CustomField>
            <div class="mt-0">
              <v-btn color="red" fab x-small class="ml-2" @click="removeItem(index)"><v-icon>delete</v-icon></v-btn>
            </div>
          </div>
        </li>
      </ol>
    </div>
    <v-btn color="appsBut2" small class="mb-4" @click="addNewItem">Add new {{ field.singular || field.name }}</v-btn>
  </div>
</template>

<script>
import CustomField from '@/components/fields/CustomField';
import ToggleBtnCustomField from '@/components/fields/ToggleBtnCustomField';
export default {
  name: 'ArrayField',
  components: { CustomField },
  props: ['value', 'field'],
  methods: {
    addNewItem() {
      const blankData = {};
      this.field.fields.forEach(item => {
        if (item.type === ToggleBtnCustomField) {
          item.items.forEach(toggleItem => {
            toggleItem.fields.forEach(child => {
              if (child.type === CustomField) {
                blankData[child.name] = {};
                return;
              }
              blankData[child.name] = null;
            });
          });
          return;
        }
        blankData[item.name] = null;
      });
      this.$emit('input', this.value.concat([blankData]));
    },
    changeItem(value, index) {
      this.$emit(
        'input',
        this.value.map((item, i) => {
          if (index === i) {
            return { ...value };
          }
          return { ...item };
        })
      );
    },
    removeItem(index) {
      this.$emit(
        'input',
        this.value.filter((item, i) => index !== i)
      );
    }
  }
};
</script>

<style scoped></style>
