<template>
  <v-file-input
    v-if="showFileUploadInput"
    :value="value"
    :rules="field.rules"
    :placeholder="field.props.placeholder"
    :accept="field.props.accept"
    prepend-icon="mdi-camera"
    solo
    light
    show-size
    dense
    class="mb-1"
    @change="changeImageValue"
  ></v-file-input>
  <div v-else class="mb-8">
    <a :href="value | storageUrl" target="_blank">{{ value }}</a>
    <v-btn color="red" x-small @click="removeImage">remove</v-btn>
  </div>
</template>

<script>
export default {
  name: 'FileUploadField',
  props: ['value', 'field'],
  computed: {
    showFileUploadInput() {
      return !this.value || typeof this.value !== 'string';
    }
  },
  methods: {
    changeImageValue(value) {
      this.$emit('input', value);
      this.$emit('changed', value);
    },
    removeImage() {
      this.changeImageValue(null);
    }
  }
};
</script>

<style scoped></style>
