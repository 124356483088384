export default {
  methods: {
    buildValidationErrorDataFromException(e) {
      // 1. Axios error message by default
      let errorData = e.message;

      // 2. Check for error message in response data
      if (((e.response || {}).data || {}).error) {
        errorData = e.response.data.error;
      }

      return errorData;
    },

    showValidationError(data) {
      // Normalize error data to array
      if (typeof data !== 'object') {
        data = [data];
      }

      let text = '<ul>';
      for (let i in data) {
        text += '<li>' + data[i] + '</li>';
      }
      text += '</ul>';

      return this.showError(text);
    },

    showError(text) {
      return this.showMessage({
        type: 'error',
        title: 'An error has occurred',
        text: text
      });
    },

    showWarning(text) {
      return this.showMessage({
        type: 'warn',
        title: 'Important notice',
        text: text
      });
    },

    showNotice(text) {
      return this.showMessage({
        type: 'success',
        title: 'Notice',
        text: text
      });
    },

    showMessage({ type, title, text }) {
      return this.$notify({
        group: 'main',
        type: type,
        title: title,
        text: text,
        duration: 30000
      });
    }
  }
};
